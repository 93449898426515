import React from 'react'
import '../styles/resume.scss'
import { resumeData } from "../static/data/resumeData.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Resume = () => {
    return (
        <>
            <div className="education">
                <h3>EDUCATION</h3>
                <ul>
                    {
                        resumeData.filter(item => item.type === "edu")
                            .map((item) => <li key={item.id}>
                                <div className="left">
                                    <FontAwesomeIcon size='2x' icon="user-graduate" />
                                    <div className="dates">
                                        <span className="from">{item.from_date}</span>
                                        <hr></hr>
                                        <span className="to">{item.to_date}</span>
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="content">
                                        <h4>{item.univ}</h4>
                                        <h5>{item.deg_field}</h5>
                                        {item.extra && <p>{item.extra}</p>}
                                    </div>
                                    <div className="foot">
                                        <span>{item.loc}</span>
                                        <span>GPA: {item.gpa}</span>
                                    </div>
                                </div>


                            </li>

                            )
                    }
                </ul>
            </div>
        </>
    )
}

export default Resume
