export const resumeData = [

    {
        "id": 1,
        "type": "edu",
        "univ": "University of Georgia, Franklin College of Arts and Sciences",
        "deg_field": "Ph.D. in Computer Science",
        "from_date": "2019",
        "to_date": "present",
        "gpa": 3.80,
        "loc": "Athens, GA"

    },
    {
        "id": 2,
        "type": "edu",
        "univ": "Iran University of Science and Technology",
        "deg_field": "Master of Science in Information Technology Engineering – (Computer Networks)",
        "from_date": "2013",
        "to_date": "2016",
        "gpa": 3.06,
        "loc": "Tehran, Iran",
        "extra": "Thesis Title: \"Performance Evaluation of Live Migration of Virtual Machines with Secured Data-Plane\""

    },
    {
        "id": 3,
        "type": "edu",
        "univ": "Azad University of Parand",
        "deg_field": "Bachelor of Science in Information Technology Engineering",
        "from_date": "2007",
        "to_date": "2012",
        "gpa": 3.00,
        "loc": "Tehran, Iran"

    }
]