export const aboutData = [
    {
        "id": 1,
        "title": "About Me",
        "paragraph": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae sint, assumenda aut doloribus ab error doloremque aliquid, fugit officia ea ipsam ullam hic rem, cumque labore fuga ipsum vero laudantium! Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae sint, assumenda aut doloribus ab error doloremque aliquid, fugit officia ea ipsam ullam hic rem, cumque labore fuga ipsum vero laudantium!Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae sint, assumenda aut doloribus ab error doloremque aliquid, fugit officia ea ipsam ullam hic rem, cumque labore fuga ipsum vero laudantium!Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae sint, assumenda aut doloribus ab error doloremque aliquid, fugit officia ea ipsam ullam hic rem, cumque labore fuga ipsum vero laudantium!Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae sint, assumenda aut doloribus ab error doloremque aliquid, fugit officia ea ipsam ullam hic rem, cumque labore fuga ipsum vero laudantium!",
        "image": "",
        "icon": "user-circle"
    },
    {
        "id": 2,
        "title": "My Hobbies",
        "paragraph": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae sint, assumenda aut doloribus ab error doloremque aliquid, fugit officia ea ipsam ullam hic rem, cumque labore fuga ipsum vero laudantium!",
        "image": "",
        "icon": "heart"
    }
]